<template>
  <main>
    <trac-loading v-if="isLoading" />
    <div class="mx-32 my-12" v-else>
      <div class="flex justify-between">
        <div>
          <div class="header">
            <div class="flex items-center">
              <img src="@/assets/svg/otherLogo.svg" alt="Traction Logo" />
              <h2 class="text-xs pl-2 pt-2 font-medium text-primaryBlue">
                traction apps
              </h2>
            </div>
          </div>
        </div>
        <div class="nav">
          <div class="links">
            <a
              href="https://tractionapps.co/"
              class="text-primaryBlue pr-4 text-xs"
              >What is TractionApps ?</a
            >
            <!-- <router-link to="login" class="button">Sign In</router-link> -->
            <trac-button @button-clicked="$router.push({ name: 'Login' })"
              >Sign in</trac-button
            >
          </div>
        </div>
      </div>

      <div class="bg-gray-400 p-4 text-center my-6">
        <h4 class="font-bold text-sm" v-if="invoicePreview.business">
          Hi
          <span style="text-transform: capitalize">{{
            invoicePreview.customer_name + ","
          }}</span>
          {{ invoicePreview.business.name }} sent you an invoice.
        </h4>
      </div>
      <div class="invoice-info">
        <ul class="flex justify-between text-xs">
          <li>Invoice Number: #{{ invoicePreview.sn | serialNumberFormat }}</li>
          <li class="font-bold" v-if="invoicePreview.balance">
            Amount Due: ₦{{
              invoicePreview.balance.outstanding.amount
                ? formatPrice(invoicePreview.balance.outstanding.amount)
                : "0.00"
            }}
          </li>
          <li>
            Due Date:
            {{ moment(invoicePreview.due_date).format("ddd MMM DD YYYY") }}
          </li>
        </ul>
      </div>
      <div class="text-right">
        <trac-button class="ml-2 mt-6" @button-clicked="downloadPDF"
          >Download PDF</trac-button
        >
        <trac-button class="ml-2 mt-6" @button-clicked="printInvoice"
          >Print Invoice</trac-button
        >
      </div>

      <div
        class="bg-white px-8 py-8 w-full mt-8 rounded-lg overflow-hidden big-shadow"
      >
        <div
          class="bg-accentLight px-8 py-12 w-full rounded-lg overflow-hidden"
        >
          <div class="flex flex-row items-center justify-between">
            <div class="flex-column w-4/12">
              <img
                class="business-logo max-w-sm"
                width="100"
                :src="invoicePreview.business.logo"
              />
            </div>
            <div class="flex-column w-4/12 text-center">
              <span
                class="my-auto text-primaryBlue font-bold text-xs uppercase"
                >{{
                  invoicePreview.title === "" ? "Invoice" : invoicePreview.title
                }}</span
              >
            </div>
            <div class="flex-column w-4/12 text-left">
              <div class="float-right">
                <span class="font-semibold text-primaryBlue text-sm">{{
                  invoicePreview.business.name
                }}</span>
                <div>
                  <ul class="text-primaryGray text-xs">
                    <li class="text-xs">
                      {{ invoicePreview.business.address.street }}
                    </li>
                    <li class="text-xs">
                      {{ invoicePreview.business.address.city }}
                    </li>
                    <li class="text-xs">
                      {{ invoicePreview.business.address.state }}
                    </li>
                    <li class="text-xs">
                      {{ invoicePreview.business.address.country }}
                    </li>
                    <li class="text-xs">
                      {{ invoicePreview.business.phone }}
                    </li>
                    <!-- <li class="text-primaryBlue underline">
                        <a href="#">Edit Business Details</a>
                      </li> -->
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-row mt-8 ml-5 justify-between">
          <div class="flex-column w-3/12">
            <ul class="text-primaryGray text-xs">
              <li class="uppercase">Bill To:</li>
              <li class="uppercase mt-2 font-semibold text-black">
                {{ invoicePreview.customer_name }}
              </li>
              <li class="mt-3">{{ invoicePreview.customer_street }}</li>
              <!-- <li>{{ invoicePreview..city }}</li> -->
              <li>{{ invoicePreview.customer_state }}</li>
              <li>{{ invoicePreview.customer_country }}</li>
            </ul>
          </div>
          <div class="flex-column w-4/12">
            <div class="d">
              <ul class="flex flex-row justify-start">
                <li class="text-xs w-5/12">Invoice Number</li>
                <li class="text-xs text-primaryGray uppercase">
                  Inv{{ invoicePreview.sn | serialNumberFormat }}
                </li>
              </ul>
              <ul class="flex flex-row">
                <li class="text-xs w-5/12">Invoice Date</li>
                <li class="text-xs text-primaryGray text-right">
                  {{
                    moment(invoicePreview.created_at).format("ddd MMM DD YYYY")
                  }}
                </li>
              </ul>
              <ul class="flex flex-row">
                <li class="text-xs w-5/12">Due Date</li>
                <li class="text-xs text-primaryGray">
                  {{
                    moment(invoicePreview.due_date).format("ddd MMM DD YYYY")
                  }}
                </li>
              </ul>
              <ul class="flex flex-row">
                <li class="text-xs w-5/12">Payment Terms</li>
                <li class="text-xs text-primaryGray">
                  {{ invoicePreview.type.description }}
                </li>
              </ul>
              <ul class="flex flex-row mt-6">
                <li class="text-xs text-primaryBlue uppercase w-5/12">
                  Amount Due
                </li>
                <li class="text-xs text-primaryGray">
                  {{
                    invoicePreview.balance.outstanding.amount
                      | formatPrice(false)
                  }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="mx-5 my-8 border rounded-lg overflow-hidden">
          <table class="w-full table-auto">
            <thead class="bg-blue-100" id="invoice-preview">
              <tr class="text-left text-xs mt-3 font-semibold uppercase h-12">
                <th class="w-7/12 pl-8">Item</th>
                <th>Quantity</th>
                <th>Price</th>
                <th>total</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="text-left text-xs mt-3 h-12"
                v-for="(item, i) in invoicePreview.items"
                :key="i"
              >
                <td class="capitalize pl-8">{{ item.name }}</td>
                <td>{{ item.quantity }}</td>
                <td>{{ item.unit_price | formatPrice }}</td>
                <td>{{ (item.unit_price * item.quantity) | formatPrice }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="mr-6 ml-6 border-b pb-6 text-right">
          <span class="font-semibold text-xs uppercase"
            >Amount Due
            <span class="text-primaryGray text-xs ml-5">{{
              invoicePreview.balance.outstanding.amount | formatPrice(false)
            }}</span>
          </span>
        </div>
        <div class="ml-6 mr-6 mt-8 border-b pb-12" v-if="invoicePreview">
          <span class="font-semibold text-sm">How to pay this invoice</span>
          <div class="flex flex-row mt-8">
            <div
              class="flex-column w-5/12"
              v-if="
                invoicePreview.payment_method.includes('online_payment') ||
                invoicePreview.payment_option === 'both'
              "
            >
              <div class="flex flex-row">
                <div class="flex-column w-3/12">
                  <img src="../../../assets/svg/card.svg" />
                </div>
                <div class="flex-column ml-10">
                  <span class="font-semibold text-sm">Pay with card</span>
                  <div class="font-light text-xs">
                    You can pay this invoice online or using your card by
                    clicking on this link below
                  </div>
                  <a
                    target="_blank"
                    :href="invoicePreview.payLink"
                    class="text-xs font-bold text-primaryBlue uppercase mt-4"
                  >
                    Make payment online
                  </a>
                </div>
              </div>
            </div>

            <div
              class="flex-column w-5/12 ml-10"
              v-if="invoicePreview.payment_method.includes('bank_transfer')"
            >
              <div class="flex flex-row">
                <div class="flex-column w-3/12">
                  <img src="../../../assets/svg/bank.svg" />
                </div>
                <div class="flex-column ml-8">
                  <span class="font-semibold text-sm"
                    >Pay using Bank transfer</span
                  >
                  <div class="font-light text-xs">
                    Pay this invoice directly by bank transfer with the
                    following details:
                  </div>
                  <div
                    class="text-xs font-bold text-primaryBlue uppercase mt-4"
                  >
                    <span v-if="invoicePreview.business">{{
                      invoicePreview.accountName2
                    }}</span>
                    <br />
                    {{ invoicePreview.bankNumber2 }}
                    <br />
                    {{ invoicePreview.settlementBank2 }}
                  </div>
                </div>
              </div>
            </div>

            <div
              class="flex-column w-5/12 ml-10"
              v-if="
                invoicePreview.payment_method.includes('own_bank_account') ||
                invoicePreview.payment_option === 'bank transfer' ||
                invoicePreview.payment_option === 'both'
              "
            >
              <div class="flex flex-row">
                <div class="flex-column w-3/12">
                  <img src="../../../assets/svg/bank.svg" />
                </div>
                <div class="flex-column ml-8">
                  <span class="font-semibold text-sm"
                    >Pay using Bank transfer</span
                  >
                  <div class="font-light text-xs">
                    Pay this invoice directly by bank transfer with the
                    following details:
                  </div>
                  <div
                    class="text-xs font-bold text-primaryBlue uppercase mt-4"
                  >
                    <span v-if="invoicePreview.business">{{
                      invoicePreview.business.name
                    }}</span>
                    <br />
                    {{ invoicePreview.bankNumber }}
                    <br />
                    {{ invoicePreview.settlementBank }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-8 ml-6">
          <span class="font-semibold text-sm">Notes</span>
          <div class="text-xs text-primaryGray mt-6" v-if="!invoicePreview.notes">
            {{
              "No note was added"
            }}
          </div>
          <div class="text-xs text-primaryGray mt-6" v-else>
            {{
              invoicePreview.notes.text === ""
                ? "No note was added"
                : invoicePreview.notes.text
            }}
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import {
  DELETE_LOCAL_DB_DATA,
  GET_LOCAL_DB_DATA,
  GET_USER_BUSINESS_DATA,
  GET_USER_DATA,
  SAVE_LOCAL_DB_DATA,
} from "../../../browser-db-config/localStorage";
import RequestPayment from "./../RequestPayment";
import moment from "moment";
import { HTTP_REQUEST } from "./../../../http-config/trac-http-req";
import { eventBus } from "./../../../main";

export default {
  name: "InvoiceDetails",
  components: { RequestPayment },
  data() {
    return {
      isLoading: false,
      makePayAction: false,
      sendInvoiceModalCtrl: false,
      recordPaymentModalCtrl: false,
      requestPaymentModalCtrl: false,
      invoice: null,
      invoicePreview: null,
      requestPaymentOption: "",
      customers: null,
      currentUserBusinessDetails: null,
      authAgentDetails: null,
      customer: null,
      loadedAssets: false,
      moment,
      sendInvoiceInfo: {
        recipients: "",
        subject: "",
        message: "",
        sendToMyself: false,
      },
      paymentRecord: {
        name: "",
        amount: "",
        date: new Date().toISOString(),
        curremcy: "NGN",
      },
    };
  },
  async created() {
    this.isLoading = true;
    this.getBusinessDetails();
    this.invoice = {
      _id: this.$route.query ? this.$route.query.invoiceId : null,
    };
    if (!this.invoice) {
      this.$router.replace({ name: 'Login' });
    }
    await this.fetchInvoiceDetails();
    this.loadedAssets = true;
    this.isLoading = false;
  },
  destroyed() {
    // SAVE_LOCAL_DB_DATA("invoice-details", this.invoice);
  },
  computed: {
    totalPaymentRecords() {
      if (!this.invoice.Payments) {
        this.invoice.Payments = [];
      }
      return this.invoice.Payments.reduce(
        (total, payment) => total + payment.amount,
        0
      );
    },
    paymentRecordDate() {
      return new Date(this.paymentRecord.date).toLocaleDateString();
    },
    webIsOffline() {
      return !window.navigator.onLine;
    },
    paymentOptions() {
      const paymentOptionsRegister = [];
      this.authAgentDetails.payment_option.forEach((payment) => {
        if (payment === "online_payment" || payment === "bank_transfer") {
          paymentOptionsRegister.push({
            payment_method: payment,
            name: payment.split("_").join(" "),
          });
        } else if (payment === "own_bank_account") {
          paymentOptionsRegister.push({
            payment_method: payment,
            name: "Bank Transfer (Dynamic)",
          });
        }
      });

      return paymentOptionsRegister;
    },
    invoiceAmount() {
      return this.invoice.balance.total.amount
        ? this.invoice.balance.total.amount
        : 0;
    },
    invoiceDue() {
      return this.invoice.balance.outstanding.amount
        ? this.invoice.balance.outstanding.amount
        : 0;
    },
  },
  methods: {
    downloadPDF() {
      window.location.href = this.invoicePreview.pdfLink;
    },
    printInvoice() {
      print();
    },
    async fetchInvoiceDetails() {
      // Fetch invoice preview
      const httpReq = {
        url: `/invoices/${this.invoice._id}`,
        method: "GET",
        authRequest: false,
      };

      const httpRes = await HTTP_REQUEST(httpReq);

      this.invoicePreview = httpRes.data;
    },
    scrollInvoiceToView() {
      const element = document.getElementById("invoice-preview");
      element.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    },
    selectPaymentOption(option) {
      this.paymentRecord.name = option.payment_method;
    },
    async checkMilestone(e, milestone) {
      this.isLoading = true;
      const payload = {
        invoiceID: this.invoice._id,
        milestone: {
          ...milestone,
          status: e.checked ? "fulfilled" : "unfulfilled",
        },
      };

      await this.$store.dispatch("UPDATE_MILESTONE", payload);
      const res = this.$store.getters["GET_INVOICE_RES"];

      this.$store.commit("SET_UP_INVOICE_RES", null);

      if (res.status) {
        // alert(res.message);
        eventBus.$emit("trac-alert", { message: res.message });
      } else {
        // alert(res.message);
        eventBus.$emit("trac-alert", { message: res.message });
      }
      this.isLoading = false;
    },
    async updateFufillment() {
      this.isLoading = true;
      await this.$store.dispatch("UPDATE_INVOICE_FULFILLMENT", this.invoice);
      const res = this.$store.getters["GET_INVOICE_RES"];

      this.$store.commit("SET_UP_INVOICE_RES", null);

      if (res.status) {
        // alert(res.message);
        eventBus.$emit("trac-alert", { message: res.message });
      } else {
        // alert(res.message);
        eventBus.$emit("trac-alert", { message: res.message });
      }
      this.isLoading = false;
    },
    async requestPayment() {
      this.isLoading = true;
      const payload = {
        invoice_id: this.invoice._id,
        payment_method: this.requestPaymentOption,
      };
      await this.$store.dispatch("REQUEST_INVOICE_PAYMENT", payload);
      const res = this.$store.getters["GET_INVOICE_RES"];

      this.$store.commit("SET_UP_INVOICE_RES", null);

      if (res.status) {
        // alert(res.message);
        eventBus.$emit("trac-alert", { message: res.message });
        this.requestPaymentOption = "";
        this.requestPaymentModalCtrl = false;
      } else {
        // alert(res.message);
        eventBus.$emit("trac-alert", { message: res.message });
      }
      this.isLoading = false;
    },
    selectCustomer(option) {
      this.invoice.client = option._id;
      this.sendInvoiceInfo.recipients = option.email;
      this.sendInvoiceInfo.message = `Hi, ${
        option.first_name + " " + option.last_name
      }.\nPlease find attached invoice for a total of ${this.formatPrice(
        this.sumInvoiceProducts
      )}.\nThank you.\n\n${this.currentUserBusinessDetails.name}.`;
    },
    // Format balance
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    async sendInvoice() {
      this.isLoading = true;
      // Setup emails
      let emails = this.sendInvoiceInfo.recipients.split(",");

      emails = emails.map((email) => email.trim());

      for (let i = 0; i < emails.length; i++) {
        const invoiceEmail = {
          invoice: this.invoice._id,
          email: emails[i],
          subject:
            this.sendInvoiceInfo.subject === ""
              ? `Invoice from ${this.currentUserBusinessDetails.name}`
              : this.sendInvoiceInfo.subject,
          note: this.sendInvoiceInfo.message,
          // payment_option: this.invoice.payment_method,
          payment_option: "",
          send_to_self: this.sendInvoiceInfo.sendToMyself,
        };
        await this.$store.dispatch("SEND_INVOICE", invoiceEmail);
      }

      const sendRes = this.$store.getters["GET_INVOICE_RES"];
      this.$store.commit("SET_UP_INVOICE_RES", null);

      // alert(sendRes.message);
      eventBus.$emit("trac-alert", { message: sendRes.message });
      if (sendRes.status) {
        this.sendInvoiceModalCtrl = false;
        const invoice = GET_LOCAL_DB_DATA("invoice-details");
        await this.fetchInvoice(invoice._id);
        await this.fetchInvoiceDetails();
      }

      this.isLoading = false;
    },
    async makePaymentRecord() {
      this.makePayAction = true;

      this.isLoading = true;
      const payload = {
        invoiceID: this.invoice._id,
        body: this.paymentRecord,
      };

      await this.$store.dispatch("UPDATE_PAYMENT", payload);
      const res = this.$store.getters["GET_INVOICE_RES"];

      this.$store.commit("SET_UP_INVOICE_RES", null);

      if (res.status) {
        this.recordPaymentModalCtrl = false;
        if (!this.invoice.Payments) {
          this.invoice.Payments = [];
        }
        this.invoice.Payments.push(this.paymentRecord);

        this.paymentRecord = {
          name: "",
          amount: "",
          date: new Date().toISOString(),
          curremcy: "NGN",
        };
        // alert(res.message);
        eventBus.$emit("trac-alert", { message: res.message });
      } else {
        // alert(res.message);
        eventBus.$emit("trac-alert", { message: res.message });
      }
      this.isLoading = false;
      this.makePayAction = false;
    },
    // async sendInvoice() {
    //   this.isLoading = true;
    //   await this.$store.dispatch("SEND_INVOICE", this.invoice);
    //   const res = this.$store.getters["GET_INVOICE_RES"];

    //   this.$store.commit("SET_UP_INVOICE_RES", null);

    //   if (res.status) {
    //     alert(res.message);
    //   } else {
    //     alert(res.message);
    //   }
    //   this.isLoading = false;
    // },
    gotoCustomerDetails() {
      SAVE_LOCAL_DB_DATA("customer-details", {
        ...this.customer,
        routeFrom: "Invoice Details",
      });
      this.$router.push({ name: "CustomerDetails" });
    },
    editInvoice() {
      SAVE_LOCAL_DB_DATA("invoice-details", this.invoice);
      this.$router.push({ name: "AddInvoice" });
    },
    async deleteInvoice() {
      if (confirm("Are you sure you want to delete this invoice?")) {
        this.isLoading = true;
        await this.$store.dispatch("DELETE_INVOICE", this.invoice);

        const res = this.$store.getters["GET_INVOICE_RES"];

        this.$store.commit("SET_UP_INVOICE_RES", null);

        if (res.status) {
          this.$router.back();
        } else {
          // alert(res.error);
          eventBus.$emit("trac-alert", { message: res.error });
        }
        this.isLoading = false;
      }
    },
    openRequestPayment() {
      this.requestPaymentModalCtrl = true;
    },
    getBusinessDetails() {
      this.currentUserBusinessDetails = GET_USER_BUSINESS_DATA();
      this.authAgentDetails = GET_USER_DATA();
    },
    changeFulfillmentValue: function (newValue) {
      this.invoice.order_status = newValue;
    },

    async fetchInvoice(invoiceID) {
      await this.$store.dispatch("FETCH_INVOICE", invoiceID);
      const res = this.$store.getters["GET_INVOICE_RES"];

      this.$store.commit("SET_UP_ALL_INVOICES", null);

      if (res.status) {
        // this.invoice = res.data.invoice;
        if (this.customers) {
          this.customer = this.customers.find(
            (cust) => cust._id === this.invoice.client
          );
        }
      } else {
        // alert(res.message);
        eventBus.$emit("trac-alert", { message: res.message });
      }
    },
    async collateCustomerBalance() {
      await this.$store.dispatch("FETCH_ALL_INVOICES");
      let invoices = this.$store.getters["GET_ALL_INVOICES"].data || [];

      invoices = invoices.invoice ? invoices.invoice : [];

      this.customers.forEach((customer) => {
        customer.balance = 0;
        // Loop through all invoices in the business
        invoices.forEach((invoice) => {
          if (customer._id === invoice.client) {
            customer.balance +=
              isNaN(+invoice.balance.outstanding.amount) ||
              +invoice.balance.outstanding.amount === undefined ||
              +invoice.balance.outstanding.amount === null
                ? 0
                : +invoice.balance.outstanding.amount;
          }
        });
      });
    },
    async fetchAllCustomers() {
      // Fetch all customers
      await this.$store.dispatch("FETCH_ALL_CUSTOMERS");
      const res = this.$store.getters["GET_ALL_CUSTOMERS"];

      if (res.status) {
        const customersData = res.data ? res.data.items || [] : [];
        this.customers = customersData;
      } else {
        // alert(res.message);
        eventBus.$emit("trac-alert", { message: res.message });
      }
    },
  },
};
</script>

<style scoped>
</style>
